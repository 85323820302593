import { createElement, ReactNode } from 'react';
import { Logger } from '@feature-hub/core';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import {
  FootnoteReference,
  getConsumptionsAndEmissionsWithIdentifiers,
} from '@oneaudi/feature-app-utils';
import type { LocaleServiceConfig } from '@volkswagen-onehub/locale-service/dist';
import { mapToTeaserProps } from './mapToTeaserProps';
import { Content } from '../EditorContentTypes';
import { ImageFadeOverOnScrollProps } from '../FeatureAppTypes';

export async function createInitialState(
  content: Content,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger?: Logger,
): Promise<ImageFadeOverOnScrollProps> {
  const consumptionsAndEmissionsValuesWithIdentifiers =
    await getConsumptionsAndEmissionsWithIdentifiers(
      content.legalData.wltpKeys,
      vueFormatterService,
      localeService as LocaleServiceConfig,
      logger,
    );
  const consumptionsAndEmissionsValues = Object.values(
    consumptionsAndEmissionsValuesWithIdentifiers,
  );

  return mapToTeaserProps(content, consumptionsAndEmissionsValues);
}

interface SerializedWltpProps {
  formattedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown>)[] | undefined;
  formattedCo2Class: (string | Record<string, unknown>)[] | undefined;
  formattedDischargedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedDischargedCo2Class: (string | Record<string, unknown>)[] | undefined;
}

export const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[],
): undefined | string | ReactNode[] => {
  if (!deserializedProperty || typeof deserializedProperty === 'string') {
    // if it's undefined or a string it doesn't contain any footnotes. Nothing to do here
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReference /> react component
    return createElement(FootnoteReference, serializedReactNode.props as undefined);
  });
};

const deserializeReactNodeArrayInWltpData = (wltpData: SerializedWltpProps[]) => {
  return wltpData.map(({ formattedConsumption, formattedEmission }) => {
    return {
      formattedConsumption: deserializeReactNodeArray(formattedConsumption),
      formattedEmission: deserializeReactNodeArray(formattedEmission),
    };
  });
};

/**
 * Helper function to deserialize the state of the feature app.
 * It converts serialized ReactNodeArray Entries that aren't string to
 * FootnoteReference components
 * @param state
 */
export function deserializeState(state: string): ImageFadeOverOnScrollProps {
  const props = JSON.parse(state);
  return {
    ...props,
    legalData: {
      ...props.legalData,
      additionalText: deserializeReactNodeArray(props.legalData.additionalText),
      wltpData: deserializeReactNodeArrayInWltpData(props.legalData.wltpData),
    },
  };
}
