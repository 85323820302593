import { Asset, isContentFragment } from '@oneaudi/falcon-tools';
import { Content, HeadlessContent } from '../EditorContentTypes';

function isFalconContent(content: unknown): content is HeadlessContent {
  return Boolean(
    isContentFragment(content) &&
      'images_firstImage_landscape' in content.fields &&
      content.fields.images_firstImage_landscape &&
      'images_firstImage_portrait' in content.fields &&
      content.fields.images_firstImage_portrait &&
      'images_secondImage_landscape' in content.fields &&
      content.fields.images_secondImage_landscape &&
      'images_secondImage_portrait' in content.fields &&
      content.fields.images_secondImage_portrait &&
      'images_altText' in content.fields &&
      content.fields.images_altText &&
      'legalData_theme' in content.fields &&
      content.fields.legalData_theme,
  );
}

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    if (!isFalconContent(content)) {
      return {
        images: {
          firstImage: {
            landscape: {
              assetValue:
                'https://s7test3.scene7.com/is/image/audistage/country/bo/es/assets/Homepage/motosport.jpg',
            },
            portrait: {
              assetValue:
                'https://s7test3.scene7.com/is/image/audistage/country/bo/es/assets/Homepage/motosport.jpg',
            },
          },
          secondImage: {
            landscape: {
              assetValue:
                'https://s7test3.scene7.com/is/image/audistage/country/bo/es/assets/Homepage/motosport.jpg',
            },
            portrait: {
              assetValue:
                'https://s7test3.scene7.com/is/image/audistage/country/bo/es/assets/Homepage/motosport.jpg',
            },
          },
          altText: 'Alt Text',
        },
        legalData: {
          theme: 'light',
          wltpKeys: [],
          additionalText: '',
        },
      };
    }
    const normalisedContent: Content = {
      images: {
        firstImage: {
          landscape: {
            assetValue: (content.fields.images_firstImage_landscape as Asset).path,
          },
          portrait: {
            assetValue: (content.fields.images_firstImage_portrait as Asset).path,
          },
        },
        secondImage: {
          landscape: {
            assetValue: (content.fields.images_secondImage_landscape as Asset).path,
          },
          portrait: {
            assetValue: (content.fields.images_secondImage_portrait as Asset).path,
          },
        },
        altText: content.fields.images_altText,
      },
      legalData: {
        theme: content.fields.legalData_theme,
        additionalText: content.fields.legalData_additionalText ?? '',
        wltpKeys: content.fields.legalData_wltpKeys,
      },
    };
    return normalisedContent;
  }
  return content as Content;
};
